import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import { Rating } from 'react-simple-star-rating'
import Loading from '../components/Loading';
// Icons
//import { AiFillStar } from 'react-icons/ai';
import noImage from '../assets/no-photo.jpg';

// Components
import Comments from '../components/Comments';
import { database } from '../firebase/config';
import AddFavorite from '../components/AddFavorite';
import { useAddReview } from '../hooks/useAddReview';
import { BackButton } from '../components/BackButton'

const Details = ({ user }) => {
  const { id } = useParams();

  const [movie, setMovie] = useState({});
  const [qualityRating, setQualityRating] = useState(0);
  const [priceRating, setPriceRating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const [comment, setComment] = useState('');
  // const [qualityNumberReviews, setQualityNumberReviews] = useState(0);
  // const [priceNumberReviews, setPriceNumberReviews] = useState(0);
  // const [serviceNumberReviews, setServiceNumberReviews] = useState(0);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const {
    addReview,
    error: firebaseError,
    success: firebaseSuccess,
    setError: firebaseSetError,
    setSuccess: firebaseSetSuccess,
    loading,
  } = useAddReview();

  const handleUpload = (e) => {
    e.preventDefault();
    firebaseSetError(null);
    firebaseSetSuccess(null);

    if (
      qualityRating === 0 || qualityRating === '' || 
      priceRating === 0 || priceRating === '' ||
      serviceRating === 0 || serviceRating === '' ||
      comment === ''
    ) {
      setError('Empty fields');
      return;
    }

    const data = {
      name: user.displayName,
      email: user.email,
      user_id: user.uid,
      product_id: id,
      qualityRating,
      priceRating,
      serviceRating,
      comment
    };

    addReview(data);
  };

  useEffect(() => {
    if (firebaseError) {
      setError(firebaseError);
    }
    if (firebaseSuccess) {
      setSuccess(firebaseSuccess);
      setQualityRating(0);
      setPriceRating(0);
      setServiceRating(0);
      setComment('')
    }
  }, [firebaseError, firebaseSuccess]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(''), 3000);
    }

    if (success) {
      setTimeout(() => setSuccess(''), 3000);
    }
  }, [error, success, loading]);

  useEffect(() => {
    const loadDocument = async () => {
      const docRef = doc(database, 'shops', id);
      const docSnap = await getDoc(docRef);

      setMovie(docSnap.data());
    };
    loadDocument();
  }, [id]);

  // const setRatingStars = (size) => {
  //   const arr = [];
  //   for (let i = 1; i <= size; i++) {
  //     arr.push(i);
  //   }
  //   return arr;
  // };


  // const handleQualityRating = (rate) => {
  //   setQualityRating(rate)
  // }

  // const handlePriceRating = (rate) => {
  //   setPriceRating(rate)
  // }

  // const handleServiceRating = (rate) => {
  //   setServiceRating(rate)
  // }

  // const handleReset = () => {
  //   // Set the initial value
  //   setQualityRating(0)
  //   setPriceRating(0)
  //   setServiceRating(0)
  // }

  return (
    <section className='flex flex-col items-center justify-center sectionHeight'>   
      <div className='flex flex-col px-16 py-8 bg-gray-100 rounded-md shadow-md max-w-[600px] w-[90%]'>
      <BackButton buttonText="Go Back"/>
      <h2 style={{textAlign: 'center'}} className='py-2 text-4xl font-bold tracking-wider text-yellow-400 transition-all duration-300 border-b-4 border-black hover:tracking-widest hover:text-yellow-300'>
      Add Your Review 
        </h2>
      <div>
        <form
        onSubmit={handleUpload}
        className='flex flex-col w-full gap-2 mt-8'
      >
        {/***** Note - Inline element working as a block need to fix this by css ***** */}
        
        <i className='transition-all duration-300 border-b-4 border-black hover:tracking-widest hover:text-yellow-300' style={{color: '#F00', textAlign: 'center', paddingBottom: '20px', borderBottom: '2px solid #DDD'}}>All fields are mendatory</i>
        Quality:
        <input
          type='number'
          placeholder='Rating (1 - 5)'
          className='w-full p-4 italic rounded-md shadow-sm outline-none'
          min={1}
          max={5}
          value={qualityRating || ''}
          onChange={(e) => setQualityRating(e.target.value)}
        />
        Price: 
        <input
          type='number'
          placeholder='Rating (1 - 5)'
          className='w-full p-4 italic rounded-md shadow-sm outline-none'
          min={1}
          max={5}
          value={priceRating || ''}
          onChange={(e) => setPriceRating(e.target.value)}
        />
        Service: 
        <input
          type='number'
          placeholder='Rating (1 - 5)'
          className='w-full p-4 italic rounded-md shadow-sm outline-none'
          min={1}
          max={5}
          value={serviceRating || ''}
          onChange={(e) => setServiceRating(e.target.value)}
        />
        Comment:
        {/************Need to replace by text area field later************ */}

        
          <textarea
          className="w-full p-2 italic text-lg border-2 rounded-lg outline-none lg:p-4 bg-slate-50 shadow-sm outline-none"
          placeholder="Write your comment.... "
          onChange={(e) => {
            setComment(e.target.value);
          }}
          rows="5" value={comment || ''}/>
        {loading ? (
          <div className='flex items-center justify-center w-full'>
            <Loading size={'30px'} />
          </div>
        ) : (
          <input
            type='submit'
            value={'Upload Review'}
            className='w-full p-4 font-bold text-white transition-all duration-300 rounded-md shadow-sm cursor-pointer bg-yellow-500 hover:bg-yellow-700 hover:tracking-wider'
          />
        )}

        {error && <p className='error'>{error}</p>}
        {success && <p className='success'>{success}</p>}
      </form>
      </div>        


      </div>
    </section>
  );
};

export default Details;
