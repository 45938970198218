import { doc, getDoc, collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Rating from '../components/Rating'
import ReactModal from 'react-modal';

import noImage from '../assets/no-photo.jpg';
import avatar from '../assets/avatar-yellow.png'

import { database } from '../firebase/config';
import AddFavorite from '../components/AddFavorite';
import { useFetchComment } from '../hooks/useFetchComment';
import { useAllFetchCommentByReview } from '../hooks/useAllFetchCommentByReview';
import { BackButton } from '../components/BackButton'

const mD = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const sD = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const customStyles = {
  content: {
    border: '6px solid #CCC'
  },
};

const Details = ({ user }) => {
  const { id } = useParams();
  //Fetch comment data on basis of : shopid or productid = id AND userid = user.uid
  const { documents: review } = useFetchComment('reviews', id);
  const [movie, setMovie] = useState({});
  const [allComments, setAllComments] = useState([]);
  const [commentId, setCommentId] = useState('');
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    if (commentId) {
      const loadDocument = async () => {
        const docRef = collection(database, 'comments');
        const q = query(docRef, where("review_id", "==", commentId), orderBy('createdAt', 'desc'),);//where("user_id", "==", userId ),
        onSnapshot(q, (querySnapshot) => {
          setAllComments(
              querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            );
          });
        
      };
      loadDocument();
    }
  }, [commentId]);

  useEffect(() => {
    const loadDocument = async () => {
      const docRef = doc(database, 'shops', id);
      const docSnap = await getDoc(docRef);
      setMovie(docSnap.data());
    };
    loadDocument();
  }, [id]);

  const setRatingStars = (size) => {
    const arr = [];
    for (let i = 1; i <= size; i++) {
      arr.push(i);
    }
    return arr;
  };

  const handleOpenModal = (id) => {
    setCommentId(id)
    setshowModal(true);
  }
  const handleCloseModal = () => {
    setshowModal(false);
  }
  return (
    <section className='mt-10'>
      
      <div className='m-auto max-w-[1200px] w-[90%] '>
        <div className='relative flex flex-col gap-10 lg:flex-row'>
          <div style={{ minWidth: '400px'}}>
          <img
            src={noImage}
            alt='poster'
            className='object-cover lg:w-[400px] lg:h-[400px] max-h-[300px] md:max-h-[400px]'
          />
          </div>
          {user && (
            <div className='absolute top-0 left-0 p-2 bg-[rgba(0,0,0,0.7)]  cursor-pointer'>
              <AddFavorite movieId={id} user={user} />
            </div>
          )}

          <div className='flex flex-col gap-2'>
            <BackButton buttonText="Go Back" />
            <h2 className='w-full text-3xl lg:text-3xl font-bold'>
              {movie.name}
            </h2>
            <div style={{minHeight: '50px'}}><p className='text-justify lg:text-sm'>{movie.name} : {movie.description}</p></div>
            <div style={{ paddingTop: '2rem'}}>
              <div>
                <Rating type="Quality" rating={movie.qualityRating} numberReviews={movie.qualityNumberReviews} customClass="flex" />
              </div>
              <div>
                <Rating type="Price" rating={movie.priceRating} numberReviews={movie.priceNumberReviews} customClass="flex"/>
              </div>
              <div>
                <Rating type="Service" rating={movie.serviceRating} numberReviews={movie.serviceNumberReviews} customClass="flex"/>
              </div>
            </div>
            <div style={{marginTop: '1rem'}} >
            
              <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 border border-yellow-700 rounded">
                <Link
                  key={id}
                  to={user ? `/addReview/${id}` : `/login`}
                  className='flex items-center'
                >
                  Click Me to Add Your Rating
                </Link>
              </button>
            </div>
          </div>
        </div>     
        <div>
            <div>
            {/* <h2 className="mt-4 text-lg text-gray-700 font-semibold">Comments</h2> */}
            {review?.map((val, key) => {
              const ratingDate = new Date(val.createdAt.seconds*1000)
                  return (
                  
              <div key={key+1} className="bg-gray-100 flex items-center justify-center">
              <div style={{width:'100%'}}>
                <div className="mt-2 bg-white rounded-2xl px-10 py-2 shadow-lg hover:shadow-2xl transition duration-500">
              <div className="mt-4">
                <div className='flex items-left justify-left'>
                <div style={{marginRight: '10rem'}}>
                  <Rating type="Quality" rating={val.qualityRating} numberReviews={val.qualityNumberReviews} customClass="flex" />
                </div>
                <div style={{marginRight: '10rem'}}>
                  <Rating type="Price" rating={val.priceRating} numberReviews={val.priceNumberReviews} customClass="flex"/>
                </div>
                <div>
                  <Rating type="Service" rating={val.serviceRating} numberReviews={val.serviceNumberReviews} customClass="flex"/>
                </div>
                </div>
                        <p className="mt-4 text-md text-gray-600">{val?.comment}.</p>
                        <div className="flex justify-between items-center">
                          <div className="mt-4 flex items-center space-x-4">
                            <div className="">
                              <img className="w-12 h-12 rounded-full" src={avatar} alt="" />
                            </div>
                            <div className="text-sm font-semibold">{val?.name ? val?.name : "User"} - <span className="font-normal"> {sD[ratingDate.getDay()] + ' '+ ratingDate.getUTCDate() + '-'+ mS[ratingDate.getUTCMonth()] + '-'+ ratingDate.getFullYear() + ' '+ ratingDate.getHours() + ':'+ ratingDate.getUTCMinutes()} </span></div>
                            {/* 5 minutes ago */}
                          </div>

                          <button onClick={() => handleOpenModal(val?.id)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 border border-yellow-700 rounded">
                            Show old comments
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  )
                })}
            </div>
       <div>
        <ReactModal 
           isOpen={showModal}
           ariaHideApp={false}
           style={customStyles}
        >
          <button onClick={handleCloseModal}>X</button>
          {allComments.length > 0 ? (<div>
            <h2 className="mt-4 text-lg text-gray-700 font-semibold hover:underline cursor-pointer">Comments</h2>
            {allComments?.map((val, key) => {
              const ratingDate = new Date(val.createdAt.seconds*1000)
              return (  
              <div key={key+1} className="bg-gray-100 flex items-center justify-center">
              <div style={{width:'100%'}}>
                <div className="mt-2 bg-white rounded-2xl px-10 py-2 shadow-lg hover:shadow-2xl transition duration-500">
              <div className="mt-4">
              
                        <p className="mt-2 text-md text-gray-600">{val?.comment}.</p>
                        <div className="flex justify-between items-center">
                          <div className="mt-2 flex items-center space-x-4">
                            <div className="">
                              <img className="w-12 h-12 rounded-full" src={avatar} alt="" />
                            </div>
                            <div className="text-sm font-semibold">{val?.name ? val?.name : "User"} - <span className="font-normal"> {sD[ratingDate.getDay()] + ' '+ ratingDate.getUTCDate() + '-'+ mS[ratingDate.getUTCMonth()] + '-'+ ratingDate.getFullYear() + ' '+ ratingDate.getHours() + ':'+ ratingDate.getUTCMinutes()} </span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  )
                })}
          </div>) : (<h2 className="mt-4 text-lg text-gray-700 font-semibold hover:underline cursor-pointer">No Comment Found</h2>)}
        </ReactModal>
      </div>         
        </div>  

      </div>
    </section>
  );
};

export default Details;
