import { useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
export const BackButton = ({buttonText, buttonWidth}) => {
    const width = buttonWidth ? buttonWidth : '6rem'
    let navigate = useNavigate();
    return (
        <>
          <Link style={{width: width}} className="pb-1 font-bold text-yellow-400 transition-all duration-150 hover:tracking-wider hover:text-yellow-300"
          onClick={() => navigate(-1)}>{buttonText}</Link> 
        </>
    );
};