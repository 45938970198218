import {
    collection,
    onSnapshot,
    orderBy,
    query,
    where,
    getDocs
  } from 'firebase/firestore';
  import { useEffect, useState } from 'react';
  import { database } from '../firebase/config';
  
  export const useFetchComment = (docCollection, productId, userId) => {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      const fetchData = async() => {
        const docRef = collection(database, docCollection);
        const q = query(docRef, where("product_id", "==", productId), orderBy('createdAt', 'desc'), );//where("user_id", "==", userId ),
        
        onSnapshot(q, (querySnapshot) => {
            setDocuments(
              querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            );
          });
      };
      fetchData();
    }, [docCollection]);
  
    return { documents };
  };
  