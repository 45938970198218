import React, { useEffect, useState } from 'react';
import noImage from '../assets/no-photo.jpg';
//import { AiFillStar } from 'react-icons/ai';
import Rating from './Rating';
//import { Rating } from 'react-simple-star-rating'
const Card = ({ index, image, title, genre, rating, qualityRating, qualityNumberReviews, priceRating, priceNumberReviews, serviceRating, serviceNumberReviews  }) => {
  //const [stars, setStars] = useState([]);
  //const [priceRating, setPriceRating] = useState(4)

  useEffect(() => {
    //setStars(setRatingStars(rating));
  }, [rating]);

  return (
    <div className='flex flex-col gap-2 relative w-[300px] sm:w-[220px] md:w-[200px] xl:w-[260px] cursor-pointer hover:scale-105 transition-all duration-200 overflow-hidden '>
      <img
        src={noImage}
        alt='poster'
        className=' w-full h-[380px] sm:h-[360px] md:h-[200px] xl:h-[220px] object-cover shadow-lg'
      />
      <div className=''>
      <h5 className='text-xl font-bold '>#{index+1} - {title}</h5>


      <div className=''>
        <div className='flex items-center justify-between'>
          <span className='flex '>
            {/* Price: <Rating readonly size = "16" initialValue={priceRating} /> */}
            <Rating type="Quality" rating={qualityRating} numberReviews={qualityNumberReviews}/>
            
          </span>
          <span className='pr-1 text-sm italic text-gray-400'>{genre}</span>
        </div>
        <div className='flex items-center justify-between'>
          <span className='flex '>
          {/* Quality: <Rating readonly size = "16" initialValue={priceRating} /> */}
          <Rating type="Price" rating={priceRating} numberReviews={priceNumberReviews}/>
            
          </span>
          <span className='pr-1 text-sm italic text-gray-400'>{genre}</span>
        </div>
        <div className='flex items-center justify-between'>
          <span className='flex '>
          {/* Service: <Rating readonly size = "16" initialValue={priceRating} /> */}
          <Rating type="Service" rating={serviceRating} numberReviews={serviceNumberReviews}/>
          </span>
          <span className='pr-1 text-sm italic text-gray-400'>{genre}</span>
        </div>
      </div>
      
           
      </div>
    </div>
  );
};

export default Card;
