import { useState } from 'react';

import { addDoc, doc, updateDoc, collection, Timestamp, getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import { database, app } from '../firebase/config';
//import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  getAuth
} from 'firebase/auth';

const WAITING_DAYS = 30

export const useAddReview = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateData = async (data) => {
    const docRef = collection(database, 'reviews');
    let priceRateSum = 0, qualityRateSum = 0, serviceRateSum = 0
    const q2 = query(docRef, where("product_id", "==", data.product_id ));
    const querySnapshot2 = await getDocs(q2);
    const querySnapshotCount = await getCountFromServer(q2);
    const numberOfRec = querySnapshotCount.data().count;    
      querySnapshot2.forEach((doc) => {
        const item = doc.data()
        priceRateSum += Number(item.priceRating)
        qualityRateSum += Number(item.qualityRating)
        serviceRateSum += Number(item.serviceRating)
      });

      const originalPriceRating = parseFloat(priceRateSum / numberOfRec)
      const originalQualityRating = parseFloat(qualityRateSum / numberOfRec)
      const originalServiceRating = parseFloat(serviceRateSum / numberOfRec)

      const finalRatingCalc = (originalPriceRating + originalQualityRating + originalServiceRating) / 3

      const priceRating = Math.round(originalPriceRating)
      const qualityRating = Math.round(originalQualityRating)
      const serviceRating = Math.round(originalServiceRating)

      const updatedData = {
        priceNumberReviews: numberOfRec,
        qualityNumberReviews: numberOfRec,
        serviceNumberReviews: numberOfRec,
        priceRating,
        qualityRating,
        serviceRating,
        originalPriceRating,
        originalQualityRating,
        originalServiceRating,
        baseRating: finalRatingCalc
      }
      //update shop table data
      const shopsRef = doc(database, "shops", data.product_id);
      updateDoc(shopsRef, updatedData)
      .then(docRef => {
        setLoading(false);
        setSuccess('Ratings added successfully');
      })
      .catch(error => {
          setError(error);
          setLoading(false);
      })
  }

  const addReview = async (data) => {
    let { priceRating, qualityRating, serviceRating} = data
    let isAlreadySaveData, docEditRef, getReviewData
    setLoading(true);

    priceRating = Number(priceRating)
    qualityRating = Number(qualityRating)
    serviceRating = Number(serviceRating)
    //Note: Can not save more than 1 review of single user
    const docRef = collection(database, 'reviews');

    const q = query(docRef, where("user_id", "==", data.user_id ), where("product_id", "==", data.product_id ));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      //docEditRef = collection(database, 'reviews', doc.id);
      // Edit Review 
      // Comment length should be greater than 100 in second time
      // Review Id - doc.id
      // Save old comments in comments - insert doc.id and comment
      getReviewData = doc.data()
      isAlreadySaveData = doc.id
    });

    if (!isAlreadySaveData) {
      const saveData = { ...data, createdAt: Timestamp.now() };
      const docData = await addDoc(docRef, saveData)
      if (docData.id) {
        let priceRateSum = 0, qualityRateSum = 0, serviceRateSum = 0
        const q2 = query(docRef, where("product_id", "==", data.product_id ));
        const querySnapshot2 = await getDocs(q2);
        const querySnapshotCount = await getCountFromServer(q2);
        const numberOfRec = querySnapshotCount.data().count;    
          querySnapshot2.forEach((doc) => {
            const item = doc.data()
            priceRateSum += Number(item.priceRating)
            qualityRateSum += Number(item.qualityRating)
            serviceRateSum += Number(item.serviceRating)
          });

          const originalPriceRating = parseFloat(priceRateSum / numberOfRec)
          const originalQualityRating = parseFloat(qualityRateSum / numberOfRec)
          const originalServiceRating = parseFloat(serviceRateSum / numberOfRec)

          const finalRatingCalc = (originalPriceRating + originalQualityRating + originalServiceRating) / 3

          const priceRating = Math.round(originalPriceRating)
          const qualityRating = Math.round(originalQualityRating)
          const serviceRating = Math.round(originalServiceRating)

          const updateData = {
            priceNumberReviews: numberOfRec,
            qualityNumberReviews: numberOfRec,
            serviceNumberReviews: numberOfRec,
            priceRating,
            qualityRating,
            serviceRating,
            originalPriceRating,
            originalQualityRating,
            originalServiceRating,
            baseRating: finalRatingCalc
          }
          //update shop table data
          const shopsRef = doc(database, "shops", data.product_id);
          updateDoc(shopsRef, updateData)
          .then(docRef => {
            setLoading(false);
            setSuccess('Ratings added successfully');
          })
          .catch(error => {
              setError(error);
              setLoading(false);
          })
        
      } else {
        setError(error);
        setLoading(false);
      }
    } else {
      const {comment, createdAt} = getReviewData
      const savedRatingDate = new Date(createdAt.seconds*1000)
      const currentDate = new Date()
      const diffTime = Math.abs(savedRatingDate - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays + " days");
      if (diffDays >= WAITING_DAYS) {
      const updatedData = { ...data, createdAt: Timestamp.now() };
      const docReviewEditRef = doc(database, "reviews", isAlreadySaveData);
      updateDoc(docReviewEditRef, updatedData)
      .then(docRef => {
        const saveData = { review_id: isAlreadySaveData, comment, createdAt };
        const docCommentsRef = collection(database, 'comments');
        addDoc(docCommentsRef, saveData)
        updateData(updatedData)
        setLoading(false);
        setSuccess('Ratings added successfully');
      })
      .catch(error => {
          setError(error);
          setLoading(false);
      })
      } else {
        setError('You can not give review more than one. Wait for ' + WAITING_DAYS  + ' days');
        setLoading(false);
      }
    }
  };

  return { addReview, success, setSuccess, error, setError, loading };
};
