import React, { useEffect, useState } from 'react';

// Router
import { Link } from 'react-router-dom';

// Components
import Card from '../components/Card';
import { useFetchData } from '../hooks/useFetchData';

const Home = () => {
  const { documents: shops } = useFetchData('shops');
  return (
    <section className='max-w-[1200px] w-[90%] mx-auto sectionHeight py-4 md:p-8'>
      <div className='grid items-start grid-flow-row grid-cols-1 gap-8 py-8 justify-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
        {
          shops?.map((shop, index) => (
            <Link
              key={shop.id}
              to={`/details/${shop.id}`}
              className='flex items-center'
            >
              <Card
                index = {index}
                image={shop.image}
                title={shop.name}
                genre={shop.genre}
                rating={shop.rating}
                qualityRating={shop.qualityRating}
                qualityNumberReviews={shop.qualityNumberReviews}
                priceRating={shop.priceRating}
                priceNumberReviews={shop.priceNumberReviews}
                serviceRating={shop.serviceRating}
                serviceNumberReviews={shop.serviceNumberReviews}
              />
            </Link>
          ))}
      </div>
    </section>
  );
};

export default Home;
