// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBERl-LZs5bTA0xhaWz5OsteQK0Q6nEYKI",
//   authDomain: "review-43a96.firebaseapp.com",
//   databaseURL: "https://review-43a96-default-rtdb.firebaseio.com",
//   projectId: "review-43a96",
//   storageBucket: "review-43a96.appspot.com",
//   messagingSenderId: "487800168392",
//   appId: "1:487800168392:web:f2f1718740c26648b36afd"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBP2oL_JrrRb9FF6c1vz4KUUbkUJAsJPlk",
  authDomain: "my-rating-app-6514c.firebaseapp.com",
  databaseURL: "https://my-rating-app-6514c-default-rtdb.firebaseio.com",
  projectId: "my-rating-app-6514c",
  storageBucket: "my-rating-app-6514c.appspot.com",
  messagingSenderId: "1000534454932",
  appId: "1:1000534454932:web:117ecdf6c391dc25d73a81",
  measurementId: "G-ZH47NZY9NT"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const storage = getStorage(app);
